import React from 'react';
import { createContext } from 'react';
import { getConfig } from '../config/portal';

export const initialABTestContext = {
  featureFlags: []
};

export const FEATURE_FLAG_KEYS = {
  THREE_COLUMN: 'three-col',
  FUZZY_SPONSORED_BOATS: 'srp-fuzzy-sponsored',
  DEFAULT_RADIUS: 'default-radius-search',
  RANDOMIZED_SPONSORED: 'randomized-sponsored-search',
  BOATS_BDP_FINANCE_V2: 'yw-bdp-finance-v2-cta',
  AD_BUTLER: 'ad-butler',
  QUESTIONNAIRE: 'yw-questionnaire',
  SBP_MANDATORY_PHONE_FIELD: 'yw-sbp-mandatory-phone-field',
  SRP_BROKER_THREE_COLUMN: 'srp-broker-three-col',
  LOCATION_FILTER: 'location-filter',
};

const getFeatureKey = (key) => {
  const kameleoonConfig = getConfig()?.scripts?.kameleoon?.featureFlags;
  return kameleoonConfig?.[key];
};

export const isFeatureFlagActive = (featureKey, featureFlags, cookies) => {
  const envFeatureKey = getFeatureKey(featureKey);
  const isFeatureFlagActive =
    Array.isArray(featureFlags) &&
    !!featureFlags.find((featureFlag) => featureFlag.key === envFeatureKey);
  return isFeatureFlagActive || isFeatureCookiePresent(cookies, envFeatureKey);
};

export const isFeatureVariationActive = (featureFlags, variation) => {
  const envFeatureV = getFeatureKey(variation);
  const isFeatureVariationActive =
    Array.isArray(featureFlags) &&
    !!featureFlags.find((featureFlag) => featureFlag.variation === envFeatureV);
  return isFeatureVariationActive;
};

export const isFeatureCookiePresent = (cookies, featureKey) => {
  const featureFlagsCookie =
    (cookies?.get
      ? cookies.get('kameleoonFeatureFlags')
      : cookies?.kameleoonFeatureFlags) ?? '';
  return featureFlagsCookie.split(',').includes(featureKey);
};

const ABTestContext = createContext(initialABTestContext);

export const ABTestProvider = ABTestContext.Provider;
const ABTestConsumer = ABTestContext.Consumer;

export const withABTestContext = (Component) => {
  const Wrapper = (props) => {
    return (
      <ABTestConsumer>
        {(context) => {
          return <Component abTestContext={context} {...props} />;
        }}
      </ABTestConsumer>
    );
  };

  return Wrapper;
};

export default ABTestContext;
